<template>
  <md-card class="subscription-card">
    <md-card-header>
      <md-card-header-text>
        <div class="md-title">{{ this.subscriptionName }}</div>
        <!-- <div class="md-subhead"></div> -->
      </md-card-header-text>
    </md-card-header>
    
    <md-card-content v-if="activeSubscription == true" class="subscription-content">
      <div>
        Subscription active ({{ this.activeSubscriptionText }})
      </div>
    </md-card-content>
    <md-card-content v-else-if="activeSubscription == false && this.subscriptionPriceInCHF != null" class="subscription-content">
      <StripeCardElement :priceInCHF="this.subscriptionPriceInCHF" :monthly="true" :setLoading="cardElementLoading"
      @handle-butten-clicked="cardElementLoading = true" @handle-payment="this.subscribe" />
    </md-card-content>

    <md-card-actions v-if="activeSubscription == true && subscriptionCanceled == false" style="margin-bottom: 20px;">
      <md-button class="md-primary md-raised" @click="this.cancel">
        Cancel Subscription
      </md-button>
    </md-card-actions>
  </md-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import StripeCardElement from './StripeCardElement'

export default {
  name: "Subscription",
  components: {
    StripeCardElement,
  },
  props: [
    'priceId'
  ],
  data() {
    return {
      subscriptionId: '',
      subscriptionName: '',
      subscriptionPriceInCHF: null,
      activeSubscription: null,
      activeSubscriptionText: '',
      subscriptionCanceled: false,

      cardElementLoading: false,
    };
  },
  computed: {
  },
  async mounted() {
    const body = {
      price_id: this.priceId
    };
    this.getSubscriptionInfo(body).then(response => {
      this.subscriptionName = response.data.product_name;
      this.subscriptionPriceInCHF = response.data.unit_amount / 100;
      this.checkSubscription();
    });
  },
  methods: {
    ...mapActions('payment', [
      'getSubscriptionInfo',
      'createSubscription',
      'cancelSubscription',
      'hasActiveSubscription',
    ]),
    checkSubscription() {
      const body = {
        price_id: this.priceId
      };
      this.hasActiveSubscription(body).then(response => {
        if (response.data.active) {
          this.activeSubscription = true;
          this.subscriptionId = response.data.sub_id;
          this.subscriptionCanceled = response.data.canceled;

          if (this.subscriptionCanceled) {
            let date = new Date(response.data.ends_on * 1000);
            this.activeSubscriptionText = "ends on: " + date.toLocaleDateString();
          } else {
            let date = new Date(response.data.renews_on * 1000);
            this.activeSubscriptionText = "renews on: " + date.toLocaleDateString()
          }
        }
        else {
          this.activeSubscription = false;
        }
        this.cardElementLoading = false;
      })
    },
    subscribe(result) {
      this.cardElementLoading = true;
      if (result.error) {
        this.showError(result.error.message);
        return;
      }

      const body = {
        price_id: this.priceId,
        payment_method_id: result.paymentMethod.id
      };
      this.createSubscription(body).then(response => {
        if (response.data.error) {
          this.cardElementLoading = false;
          this.$notify({ text: response.data.error });
        } else {
          this.checkSubscription();
        }
      }).catch(_error => {
        this.cardElementLoading = false;
        this.$notify({ text: this.$t('payment.paymentFailed') });
      });
    },
    cancel() {
      const body = {
        subscription_id: this.subscriptionId
      }
      this.cancelSubscription(body).then(response => {
        this.checkSubscription();
      });
    },
    showError(errorMsgText) {
      this.cardElementLoading = false;
      var errorMsg = document.querySelector('.sr-field-error');
      errorMsg.textContent = errorMsgText;
      setTimeout(() => { errorMsg.textContent = ''; }, 10000);
    },
  },
};

</script>

<style scoped>

.subscription-card {
  width: 500px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin: 8px;
}

.subscription-content {
  flex-grow: 1;
  display: flex;
  align-items: center;
}
</style>
