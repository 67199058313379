<template>
    <div id="payInput">
        <div class="sr-root">
            <div class="sr-main">
                <p>{{ $t('payment.enterCardDetails') }}</p>
                <p>{{ $t('payment.amount') }}: {{ priceInCHF }} CHF {{ $t('payment.inclusiveVat') }} {{ monthly ? $t('payment.perMonth') : '' }}</p>
                <form id="payment-form" class="sr-payment-form">
                    <div class="sr-combo-inputs-row">
                        <div class="sr-input sr-card-element" id="card-element-subscription"></div>
                    </div>
                    <div class="sr-field-error" id="card-errors-subscription" role="alert" style="color: crimson;">
                    </div>
                    <button id="submit" @click.prevent="handleClick()" :disabled=btnDisabled>
                        <div class="spinner hidden" id="spinner"></div>
                        <span id="button-text">{{ $t('payment.btnPayment') }}</span><span id="order-amount"></span>
                    </button>
                </form>
                <p class="footnote">{{ $t('payment.processedByStripe') }} - <a href="https://stripe.com"
                        target="_blank">stripe.com</a></p>
                <p class="footnote"><md-icon>lock</md-icon> <span>{{ $t('payment.cardIsEncrypted') }}</span></p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: [
        'priceInCHF',
        'setLoading',
        'monthly',
    ],
    data() {
        return {
            btnDisabled: false,
            stripe: '',
            elements: null,
            cardElement: null,
        };
    },
    watch: {
        setLoading: function (newVal, oldVal) {
            this.changeLoadingState(newVal);
        }
    },
    computed: {
        ...mapGetters('payment', [
            'getPublicKey',
        ]),
        ...mapGetters('user', {
            user: 'getEditedUserDetails',
        }),
    },
    async mounted() {
        await this.fetchPublicKey();
        try {
            this.stripe = Stripe(this.getPublicKey);
            this.elements = this.stripe.elements();
        } catch (IntegrationError) {
            this.$notify({ text: this.$t('payment.stripeKeyIssue') });
        }

        const style = {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        };

        try {
            this.cardElement = this.elements.create('card', { style: style });
            this.cardElement.mount('#card-element-subscription');
        } catch (IntegrationError) {
            this.$notify({ text: this.$t('payment.stripeLoadIssue') });
        }
    },
    methods: {
        ...mapActions('payment', [
            'fetchPublicKey',
        ]),
        changeLoadingState(isLoading) {
            if (isLoading) {
                document.querySelector('#spinner').classList.remove('hidden');
                document.querySelector('#button-text').classList.add('hidden');
                this.btnDisabled = true;
            } else {
                document.querySelector('#spinner').classList.add('hidden');
                document.querySelector('#button-text').classList.remove('hidden');
                this.btnDisabled = false;
            }
        },
        handleClick() {
            this.$emit('handle-butten-clicked');

            // step 1: create payment method
            this.stripe.createPaymentMethod({
                type: 'card',
                card: this.cardElement,
                billing_details: {
                    // Include any additional collected billing details.
                    name: this.user.name,
                    email: this.user.email,
                },
            }).then(result => {
                this.$emit('handle-payment', result);
            });
        },
    },
};

</script>

<style lang="scss">
@import '../../assets/scss/payment-stripe.scss';

#payInput {
    padding-top: 0;

    .footnote {
        color: gray;
        text-align: center;
    }
}
</style>