<template>
  <div>
    <md-card>
      <md-card-header>
        <label class="md-title">{{ $t('audioSettings.upload') }}</label>
      </md-card-header>
      <md-card-content>
        <AudioLoader @set-audio-too-big="setAudioTooBig($event)" @set-audio="uploadAudio($event)" />
      </md-card-content>
    </md-card>
    <md-card>
      <md-card-header>
        <label class="md-title">{{ $t('audioSettings.recording') }}</label>
      </md-card-header>
      <md-card-content>
        <AudioRecorder @set-audio-too-big="setAudioTooBig($event)" @set-audio="uploadAudio($event)" />
        <span class="md-error" v-if="isAudioTooBig">{{ $t("loadAudio.sizeTooBig") }} (> 1 GB)</span>
      </md-card-content>
    </md-card>

    <div v-if="isUploadingAudio">
      <md-progress-spinner :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
      <span class="spinner-text">{{ $t("loadAudio.spinnerText") }}</span>
    </div>
  </div>
</template>

<script>
import AudioLoader from "./AudioLoader";
import AudioRecorder from "./AudioRecorder";
import ApiService from "../../services/api.service";

export default {
  name: "AudioUpload",
  components: {
    AudioLoader,
    AudioRecorder,
    ApiService,
  },
  data() {
    return {
      isUploadingAudio: false,
      isAudioTooBig: false,
    };
  },
  methods: {
    uploadAudio(audio_file) {
      this.isUploadingAudio = true;

      // Create hidden form so that the audio file can be sent to the server
      const formData = new FormData();
      formData.append("audio", audio_file.file);
      formData.append("contentType", audio_file.metadata.contentType);

      // Post the audio file and the content type to the server
      const reqData = {
        method: "post",
        url: "/Audiofile.create",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };
      ApiService.custom(reqData)
        .then((response) => {
          const audio = response.data.audio;
          const audioMetaData = {
            channelCount: audio.audio_channel_count,
            fileType: audio.file_type,
            sampleRate: audio.sample_rate,
            length: audio.length,
            uploadName: audio_file.file.name,
          };
          this.isUploadingAudio = false;
          this.$emit("set-audio-meta", audioMetaData);
          this.$emit("set-audio-file-id", audio.id);
          this.$emit("on-audio-uploaded", audio.length);
        })
        .catch((error) => this.$notify({ text: error }));
    },
    setAudioTooBig(value) {
      this.isAudioTooBig = value;
    },
  },
};
</script>


<style scoped>
.md-card {
  width: 48%;
  margin: 4px;
  display: inline-block;
  vertical-align: top;
}

@media (max-width: 876px) {
  .md-card {
    width: 100%;
  }

}
</style>