<template>
  <div class="card-holder" >
    <div v-for="priceId in this.priceIds" :key="priceId">
      <Subscription :priceId="priceId" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Subscription from './payment/Subscription';

export default {
  name: "SubscriptionTab",
  components: {
    Subscription,
  },
  data: () => ({
    priceIds: [],
  }),
  computed: {
  },
  watch: {
  },
  mounted() {
    this.getSubscriptionPlans().then(result => {
      this.priceIds = result.data.data.plans;
    });
  },
  methods: {
    ...mapActions('payment', [
      'getSubscriptionPlans',
    ]),
  },
};
</script>

<style scoped>
.card-holder {
  display: flex;
  justify-content: center;
}
</style>
